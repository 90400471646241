import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../js/stores/form';

export const redirectToEnquirerDashboard = (productId, url, data) => {
    const formStore = useFormStore(productId);
    const { enquirerDashboard } = storeToRefs(formStore);

    if (!enquirerDashboard.value || data.id === undefined) return;

    setTimeout(() => {
        window.location.href = url + data.id;
    }, 3000);
};
