import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../js/stores/form';

export const goToQuestion = (productId, nextQuestion = 'next_question') => {
    const formStore = useFormStore(productId);
    const { step, state } = storeToRefs(formStore);

    if (state.advancedFlow && nextQuestion !== '' && nextQuestion !== 'next_question') {
        step.value = Number(nextQuestion);
        return;
    }

    step.value++;
};
