import { useFormStore } from '../../../js/stores/form';

export const generateFormData = formId => {
    const formStore = useFormStore(formId);
    const { steps } = formStore;

    const formData = new FormData();

    steps.forEach(step => {
        for (const [key, value] of Object.entries(step.fields)) {
            formData.append(key, value.value);
        }
    });

    return formData;
};
