export default (key, field) => {
    // const type = field.type;

    // Space for type specific validation
    if (field.validation === 'notRequired') {
        return true;
    }

    // Default validation
    if (field.validation) {
        let pattern = field.validation;
        if (pattern.startsWith('/')) {
            pattern = pattern.substring(1);
        }
        if (pattern.endsWith('/')) {
            pattern = pattern.slice(0, -1);
        }

        const regExp = new RegExp(pattern, 'g');

        if (!regExp.test(field.value)) {
            return false;
        }
    }

    if (!field.value) {
        return false;
    }

    return true;
};
