import { useFormStore } from '../../../js/stores/form';

export const addProductId = (id = 1) => {
    const formStore = useFormStore(id);
    const { initializeField } = formStore;

    initializeField(0, {
        system_key: 'productId',
        value: id,
    });
};

export const setProductId = id => {
    const formStore = useFormStore(id);
    const { setFieldData } = formStore;

    if (!id) return;

    setFieldData(0, 'productId', 'value', id);
};

export const checkProductIdOverride = (productId, field) => {
    const formStore = useFormStore(productId);
    const { getFieldData } = formStore;

    if (field['quote_forms_steps_fields_options']) {
        for (const option of field['quote_forms_steps_fields_options']) {
            if (option['quote_forms_steps_fields_options_item_product_id']) {
                if (getFieldData(field.system_key, 'value') === option['quote_forms_steps_fields_options_item_value']) {
                    setProductId(option['quote_forms_steps_fields_options_item_product_id']);
                }
            }
        }
    }
};
