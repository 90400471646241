import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../js/stores/form';
import validateFormStep from './validate-form-step';
import { submitForm } from './submit-form';
import { checkProductIdOverride } from './product-id';
import gtag from './gtag';
import { checkRedirect } from './redirect';
import { goToQuestion } from './go-to-question';

export default (productId, nextStepProp = null) => {
    const formStore = useFormStore(productId);
    const { step, steps, totalSteps, previousStep, state, questionFlow, nextStep, savedSteps, savedFlow, lastAnswer, zoeRedirect } = storeToRefs(formStore);
    const { getFieldData } = formStore;

    if (state.value.loading) return;

    state.value.loading = true;

    window.$cookies.set('cookieConsent', 1);

    gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
    });

    const _paq = window._paq || [];
    _paq.push([function () {
        if (!this.hasRememberedConsent()) {
            _paq.push(['rememberConsentGiven']);
        }
    }]);

    const valid = validateFormStep(productId, step.value);

    if (!valid) {
        state.value.loading = false;
        return;
    }

    if (zoeRedirect.value) {
        state.value.loading = false;
        const url = new URL(zoeRedirect.value);
        url.searchParams.append('first_question', lastAnswer.value);
        url.searchParams.append('origin', window.location.href);
        window.location.href = url.href;
        return;
    } else if (getFieldData('zoeRedirect', 'value')) {
        state.value.loading = false;
        const url = new URL(getFieldData('zoeRedirect', 'value'));
        url.searchParams.append('first_question', lastAnswer.value);
        url.searchParams.append('origin', window.location.href);
        window.location.href = url.href;
        return;
    }

    for (const field of Object.values(steps.value[step.value].fields)) {
        checkProductIdOverride(productId, field);
        checkRedirect(field);
    }

    if (totalSteps.value === step.value) {
        submitForm(productId);
        return;
    }

    state.value.loading = false;
    previousStep.value = step.value;

    if ((nextStepProp === null && nextStep.value === null) || nextStepProp === '') {
        nextStepProp = Number(step.value) + 1;
    } else if (nextStepProp === null) {
        nextStepProp = nextStep.value;
        nextStep.value = null;
    }

    nextStepProp = Number(nextStepProp);

    if (!nextStepProp || nextStepProp === '') {
        if (!questionFlow.value.includes(step.value + 1)) {
            questionFlow.value.push(step.value + 1);
            nextStepProp = step.value + 1;

            if (!savedFlow.value.includes(step.value + 1)) {
                savedFlow.value.push(step.value + 1);
            }
        }
    } else {
        if (!questionFlow.value.includes(Number(nextStepProp))) {
            questionFlow.value.push(Number(nextStepProp));

            if (!savedFlow.value.includes(Number(nextStepProp))) {
                savedFlow.value.push(Number(nextStepProp));
            }
        }
    }

    if (!checkFlowChange(questionFlow.value, savedFlow.value)) {
        savedFlow.value = questionFlow.value;
        savedSteps.value = [];
    }

    if (nextStepProp === null) {
        nextStepProp = steps.value[step.value];
    }

    if (nextStepProp !== savedSteps.value[0]) {
        savedSteps.value = [];
    }

    if (savedSteps.value.length > 0) {
        const lastSavedStep = savedSteps.value[savedSteps.value.length - 1];
        nextStepProp = lastSavedStep;
        savedSteps.value = savedSteps.value.filter(step => step !== nextStepProp);
    }

    goToQuestion(productId, nextStepProp);
};

const checkFlowChange = (questionFlow, savedFlow) => {
    let sameFlow = true;
    questionFlow.forEach((item, index) => {
        if (item !== savedFlow[index]) {
            sameFlow = false;
        }
    });

    return sameFlow;
};
