import { storeToRefs } from 'pinia';
import { useFormStore } from '../../../js/stores/form';
import validateFormField from './validate-form-field';

export default (productId, step) => {
    const formStore = useFormStore(productId);
    const { steps } = storeToRefs(formStore);
    let valid = true;

    Object.entries(steps.value[step].fields).forEach(([key, field]) => {
        const fieldValid = validateFormField(key, field);
        steps.value[step].fields[key].valid = fieldValid;
        if (!fieldValid) valid = false;
    });

    if (!valid) return false;

    steps.value[step].valid = true;

    return valid;
};
