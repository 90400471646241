/* global grecaptcha */
import { storeToRefs } from 'pinia';
import { usePage } from '@inertiajs/vue3';
import axios from 'axios';
import { useFormStore } from '../../../js/stores/form';
import gtag from './gtag';
import { redirectToEnquirerDashboard } from './enquirer-dashboard';
import { generateFormData } from './form-data';
import prepareRecaptcha from '../../../js/utils/prepare-recaptcha';

export const submitForm = async productId => {
    const page = usePage();
    const translations = page.props.settings.translations.quoteForm;
    const recaptchaSiteKey = page.props.settings.recaptchaSiteKey;

    await prepareRecaptcha(recaptchaSiteKey);

    const formStore = useFormStore(productId);
    const { quoteCode, errors, verification, state } = storeToRefs(formStore);

    const formData = generateFormData(productId);

    state.value.loading = true;
    errors.value.submit = null;
    errors.value.sherlock = null;

    grecaptcha.ready(() => {
        grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(recaptchaToken => {
            formData.append('recaptchaToken', recaptchaToken);

            axios.post('/quote-form', formData)
                .then(({ data }) => {
                    gtag({
                        event: 'Form Submit',
                        event_category: 'QuoteFormCompleted',
                    });

                    state.value.form = false;
                    quoteCode.value = data.id;

                    if (data?.metadata?.hash) {
                        verification.value.hash = data.metadata.hash;
                        verification.value.phone = data.metadata.phone;
                    }

                    if (page.props.settings.quoteFormMarketingConsent) {
                        state.value.marketingConsent = true;
                    } else if (verification.value.hash) {
                        state.value.verification = true;
                    } else {
                        state.value.thankYou = true;
                        redirectToEnquirerDashboard(productId, page.props.settings.enquirerDashboardUrl, data);
                    }
                })
                .catch(error => {
                    errors.value.submit = error.response.data.message === 'try_again' ? translations.errorTryAgain : translations.errorContactSupport;

                    if (error.response.data.error) {
                        errors.value.sherlock = error.response.data.error[0].length > 1 ? error.response.data.error[0] : error.response.data.error;
                    }

                    state.value.loading = false;
                });
        });
    });
};
