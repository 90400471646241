import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFormStore = (id = 1) => defineStore(`form-${id}`, () => {
    const step = ref(1);
    const previousStep = ref(1);
    const nextStep = ref(null);
    const savedSteps = ref([]);
    const steps = ref([]);
    const totalSteps = ref(0);
    const allFlows = ref([]);
    const currentFlows = ref([]);
    const savedFlow = ref([1]);
    const equalFlow = ref(true);
    const totalFlowLength = ref(1);
    const questionFlow = ref([1]);
    const enquirerDashboard = ref(false);
    const quoteCode = ref(null);
    const verification = ref({ hash: '', phone: '' });
    const errors = ref({ submit: null, sherlock: null });
    const lastAnswer = ref(null);
    const zoeRedirect = ref(false);
    const state = ref({
        advancedFlow: false,
        loading: false,
        form: true,
        verification: false,
        marketingConsent: false,
        thankYou: false,
    });

    const initializeStep = index => {
        if (steps.value[index]) return;
        steps.value[index] = {
            validate: null,
            fields: {},
        };
    };

    const initializeField = (step, field) => {
        if (steps.value[step].fields[field.system_key]) return;
        steps.value[step].fields[field.system_key] = {
            system_key: field.system_key,
            type: field.option,
            valid: null,
            validation: field.validation_regex,
            value: field.value || '',
        };
    };

    const getFieldData = (field, key) => {
        let data = null;

        steps.value.forEach(step => {
            if (step.fields[field]) data = step.fields[field][key];
        });

        return data;
    };

    const setFieldData = (field, key, value) => {
        steps.value.forEach(step => {
            if (step.fields[field]) step.fields[field][key] = value;
        });
    };

    const initializeTotal = length => {
        totalSteps.value = length;
    };

    const setEnquiryDashboard = enabled => {
        enquirerDashboard.value = enabled;
    };

    return {
        step,
        steps,
        previousStep,
        nextStep,
        savedSteps,
        totalSteps,
        allFlows,
        currentFlows,
        savedFlow,
        equalFlow,
        totalFlowLength,
        questionFlow,
        enquirerDashboard,
        quoteCode,
        verification,
        errors,
        lastAnswer,
        zoeRedirect,
        state,
        initializeStep,
        initializeField,
        initializeTotal,
        setEnquiryDashboard,
        getFieldData,
        setFieldData,
    };
})();
